import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $archiveWork = $('.post-type-archive-work');
  const hasAarchiveWork = $archiveWork && $archiveWork.length;

  if (hasWindow && hasDocument && hasAarchiveWork) {
    const $switchWorksTypeView = $archiveWork.find('.switch-works-type-view');
    const $typeViewButtton = $switchWorksTypeView.find('.type-view-buttton');
    const hasTypeViewButtton = $typeViewButtton && $typeViewButtton.length;
    const $worksListSection = $archiveWork.find('.works-list-section');
    const hasWorksListSection = $worksListSection && $worksListSection.length;
    const $workItem = $worksListSection.find('.work-item');
    const hasWorkItem = $workItem && $workItem.length;
    const $workTitle = $workItem.find('.work-item-info .work-title');
    const hasWorkTitle = $workTitle && $workTitle.length;
    let windowHeight = 0;

    const worksListViewStartAnimation = () => {
      let transitionTime = 200;

      if (windowHeight >= 1024) {
        transitionTime = 150; 
      }

      if ($worksListSection.hasClass('works-list-view')) {
        $workItem.removeClass('visible');
        $archiveWork.removeClass('show-menu');

        let workItemIndex = 0;
        const worksListViewStartAnimation = setInterval(() => {
          const $currentWorkItem = $workItem.eq(workItemIndex);
          const currentWorkItemVisiblePosition = $currentWorkItem.offset().top + ($currentWorkItem.innerHeight() * 1.25);

          if (currentWorkItemVisiblePosition < windowHeight) {
            $currentWorkItem.addClass('visible');
          } else {
            $archiveWork.addClass('show-menu');
            clearInterval(worksListViewStartAnimation);
          }

          workItemIndex = workItemIndex + 1;
        }, transitionTime);
      }
    }

    const worksListViewScrollAnimation = () => {
      const scrollTop = $window.scrollTop();
      const fixItemHeight = $worksListSection.hasClass('works-list-view') ? 1.25 : 0.25;

      $workItem.each(index => {
        const $currentWorkItem = $workItem.eq(index);
        let currentWorkItemVisiblePosition = $currentWorkItem.innerHeight() * fixItemHeight;
        currentWorkItemVisiblePosition = $currentWorkItem.offset().top + currentWorkItemVisiblePosition;

        if (currentWorkItemVisiblePosition < scrollTop + windowHeight) {
          $currentWorkItem.addClass('visible');
        } else {
          $currentWorkItem.removeClass('visible');
        }
      });
    }

    const onResizeWindow = () => {
      const windowWidth = $window.innerWidth();
      const switchWorksTypeViewWithDisableClass = $switchWorksTypeView.hasClass(
        'disable-type-switch',
      );

      if (switchWorksTypeViewWithDisableClass && windowWidth >= 768) {
        $switchWorksTypeView.removeClass('disable-type-switch');
      }

      windowHeight = $window.height();

      onWindowScroll();
    }

    const onWindowScroll = () => {
      if (!$archiveWork.hasClass('show-menu')) {
        $archiveWork.addClass('show-menu');
      }

      worksListViewScrollAnimation();
    }

    const onDocumentReady = () => {
      $window.scrollTop(0);

      if (hasWorksListSection && hasTypeViewButtton) {
        const onClickTypeViewButtton = event => {
          const switchWorksTypeViewWithDisableClass = $switchWorksTypeView.hasClass(
            'disable-type-switch',
          );

          if (!switchWorksTypeViewWithDisableClass) {
            const $currentTarget = $(event.currentTarget);
            const currentTargetDataTyle = $currentTarget.attr('data-type');

            $worksListSection.removeClass('works-gallery-view works-list-view');
            $typeViewButtton.removeClass('active');
            $worksListSection.addClass(`works-${currentTargetDataTyle}-view`);
            $currentTarget.addClass('active');
            $archiveWork.removeClass('item-active');
          }

          onResizeWindow();

          event.preventDefault();
        }

        onResizeWindow();
        $typeViewButtton.click(onClickTypeViewButtton);
      }

      if (hasWorkItem && hasWorkTitle) {
        const workClientNameMouseenter = event => {
          if ($('body').hasClass('mobile-tablet')) {
            return;
          }

          const $currentTarget = $(event.currentTarget);
          const $closestWorkItem = $currentTarget.closest('.work-item');

          $workItem.removeClass('active');
          $workItem.addClass('inactive');
          $closestWorkItem.addClass('active');
          $closestWorkItem.removeClass('inactive');
          $archiveWork.addClass('item-active');

          if ($worksListSection.hasClass('works-list-view')) {
            $archiveWork.addClass('item-active');
          } else {
            $archiveWork.removeClass('item-active');
          }
        }

        const workClientNameMouseleave = () => {
          if ($('body').hasClass('mobile-tablet')) {
            return;
          }

          $workItem.removeClass('active inactive');
          $archiveWork.removeClass('item-active');
        }

        $workTitle.mouseenter(workClientNameMouseenter);
        $workTitle.mouseleave(workClientNameMouseleave);
      }

      onResizeWindow();
      setTimeout(worksListViewStartAnimation, 10);

      $window.resize(onResizeWindow);
      $window.scroll(onWindowScroll);
    }

    $document.ready(onDocumentReady);
  }

})();
