import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $header = $('#header');
  const hasHeader = $header && $header.length;
  const $switchWorksTypeView = $('.switch-works-type-view');
  const hasSwitchWorksTypeView = $switchWorksTypeView && $switchWorksTypeView.length;

  if (hasWindow && hasDocument && hasHeader) {
    const mobileCheck = () => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent)) {
        return true;
      }

      return false;
    }

    const onResizeWindow = () => {
      const windowWidth = $window.innerWidth();
      const headerWithClassVisibleNavigation = $header.hasClass('visible-background');

      if (headerWithClassVisibleNavigation && windowWidth >= 768) {
        $header.removeClass('visible-background');
      }
    }

    const onDocumentReady = () => {
      const $showNavigation = $header.find('.show-navigation');
      const hasShowNavigation = $showNavigation && $showNavigation.length;
      const isMobileOrTablet = mobileCheck();

      if (hasShowNavigation) {
        const onClickShowNavigation = () => {
          if ($header.hasClass('visible-background')) {
            $header.removeClass('visible-background')
  
            if (hasSwitchWorksTypeView) {
              $switchWorksTypeView.removeClass('disable-type-switch');
            }
          } else {
            $header.addClass('visible-background');
  
            if (hasSwitchWorksTypeView) {
              $switchWorksTypeView.addClass('disable-type-switch');
            }
          }
        }
  
        $showNavigation.click(onClickShowNavigation);
      }

      if (isMobileOrTablet) {
        $('body').addClass('mobile-tablet');
      }

      onResizeWindow();
    }

    $document.ready(onDocumentReady);
    $window.resize(onResizeWindow);
  }

})();
