import $ from 'jquery';

;(() => {
  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $footer = $('#footer');
  const hasFooter = $footer && $footer.length;
  const $footerPrompt = $footer.find('.footer-prompt');
  const hasFooterPrompt = $footerPrompt && $footerPrompt.length;
  const $footerPromptContentWrapper = $footerPrompt.find('.prompt-content-wrapper');
  const hasFooterPromptContentWrapper = $footerPromptContentWrapper
    && $footerPromptContentWrapper.length;
  const $footerPrompText = $footerPrompt.find('.prompt-text');
  const hasFooterPromptText = $footerPrompText && $footerPrompText.length;

  if (
    hasWindow &&
    hasDocument &&
    hasFooter &&
    hasFooterPrompt &&
    hasFooterPromptContentWrapper &&
    hasFooterPromptText
  ) {
    const activatePromptAnimation = $footerPrompt.hasClass('activate-animation');

    if (activatePromptAnimation) {
      const $footerPromptItemCont = $footerPrompt.find('.item-cont');
      const footerPrompTextAmount = $footerPrompText.length;
      let windowHeight = 0;
      let footerOffsetTop = 0;
      let currentTextIndex = 0;
      let currentLetterIndex = 1;
      let textToTyping = $footerPrompText.eq(currentTextIndex).attr('value');
      let currentStatus = 'typing';
      let promptInterval = null;

      const onResizeWindow = () => {
        windowHeight = $window.height();
        footerOffsetTop = $footer.offset().top;
      };

      const promptAnimation = () => {
        if (currentStatus === 'typing') {
          if (currentLetterIndex >= textToTyping.length) {
            currentStatus = 'waiting';
            currentLetterIndex = textToTyping.length;
          } else {
            currentLetterIndex = currentLetterIndex + 1;
          }

          $footerPromptItemCont.text(textToTyping.substring(0, currentLetterIndex));
        }

        if (currentStatus === 'waiting') {
          if (currentLetterIndex <= textToTyping.length - 20) {
            currentStatus = 'deleting';
            currentLetterIndex = textToTyping.length;
          } else {
            currentLetterIndex = currentLetterIndex - 1;
          }
        }

        if (currentStatus === 'deleting') {
          if (currentLetterIndex <= 0) {
            currentStatus = 'typing';
            currentLetterIndex = 1;
            currentTextIndex = currentTextIndex + 1;

            if (currentTextIndex >= footerPrompTextAmount) {
              currentTextIndex = 0;
            }

            textToTyping = $footerPrompText.eq(currentTextIndex).attr('value');
          } else {
            currentLetterIndex = currentLetterIndex - 2;
          }

          $footerPromptItemCont.text(textToTyping.substring(0, currentLetterIndex));
        }
      }

      const onScrollWindow = () => {
        const scrollPosition = $window.scrollTop();

        if ((scrollPosition >= footerOffsetTop - (windowHeight * 2)) && !promptInterval) {
          promptInterval = setInterval(promptAnimation, 50);
        } else if ((scrollPosition < footerOffsetTop - (windowHeight * 2)) && promptInterval) {
          promptInterval = clearInterval(promptInterval);
        }
      }

      const onDocumentReady = () => {
        onResizeWindow();
        onScrollWindow();

        $window.scroll(onScrollWindow);
        $window.resize(onResizeWindow);
      }

      $document.ready(onDocumentReady);
    }
  }

})();
