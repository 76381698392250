import $ from 'jquery';

;(() => {
  const $profileAccordion = $('.profile-accordion');

  if ($profileAccordion && $profileAccordion.length) {
    const $accordionItemHead = $profileAccordion.find('.accordion-item-head');

    if ($accordionItemHead && $accordionItemHead.length) {
      const onClickAccordionItemHead = event => {
        const $currentTarget = $(event.currentTarget);
        const $currentAccordionItem = $currentTarget.closest('.accordion-item');

        if ($currentAccordionItem.hasClass('expanded')) {
            $currentAccordionItem.removeClass('expanded');
        } else {
            $currentAccordionItem.addClass('expanded');
        }
      }

      $accordionItemHead.click(onClickAccordionItemHead);
    }
  }
})();
