import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length > 0;
  const $document = $(document);
  const hasDocument = $document && $document.length > 0;
  const $landing = $('.page-template-landing');
  const hasLanding = $landing && $landing.length > 0;
  const $landingImageScroll = $landing.find('.landing-image-scroll');
  const hasLandingImageScroll = $landingImageScroll && $landingImageScroll.length > 0;
  const $landingImageScrollImage = $landingImageScroll.find('.image-wrapper');
  const hasLandingImageScrollImage = $landingImageScrollImage
    && $landingImageScrollImage.length > 0;

  if (
    hasWindow &&
    hasDocument &&
    hasLanding &&
    hasLandingImageScroll &&
    hasLandingImageScrollImage
  ) {
    let windowHeight = 0;
    let windowWidth = 0;
    let landingImageScrollImageWidth = 0;
    let offsetTopList = [];

    const getOffsetTopList = () => {
      offsetTopList = [];

      $landingImageScroll.each((index) => {
        offsetTopList.push($landingImageScroll.eq(index).offset().top);
      })
    }

    const updateLandingImageScroll = ($element, scrollPosition, offsetTop) => {
      const fixedPosition = offsetTop;
      const fullWidthPostion = offsetTop + windowHeight;
      const absolutePostion = offsetTop + windowHeight + (windowHeight / 4);

      if (scrollPosition < fixedPosition) {
        $element.removeClass('fixed');
        $element.removeClass('absolute');
        $element.css('width',  '');
      } else if (scrollPosition >= fixedPosition && scrollPosition < absolutePostion) {
        const percente = (scrollPosition - fixedPosition) / (fullWidthPostion - fixedPosition);
        const widthToGrow = windowWidth - landingImageScrollImageWidth;

        $element.addClass('fixed');
        $element.removeClass('absolute');
        
        if (scrollPosition >= fullWidthPostion && scrollPosition < absolutePostion) {
          $element.removeClass('absolute');
          $element.addClass('full-width');
          $element.css('width', '');
        } else {
          $element.removeClass('full-width');
          $element.css('width',  `${landingImageScrollImageWidth + (widthToGrow * percente)}px`);
        }
      } else if (scrollPosition >= absolutePostion) {
        $element.removeClass('fixed');
        $element.addClass('absolute');
        $element.css('width', '');
      }
    }

    const onScrollWindow = () => {
      const scrollPosition = $window.scrollTop();

      $landingImageScroll.each(index => {
        const $element = $landingImageScroll.eq(index).find('.image-wrapper');

        updateLandingImageScroll($element, scrollPosition, offsetTopList[index]);
      })

    }

    const onResizeWindow = () => {
      $landingImageScrollImage.removeClass('fixed full-width absolute');
      $landingImageScrollImage.css('width', '');
      windowHeight = $window.height();
      windowWidth = $window.width();
      landingImageScrollImageWidth = $landingImageScrollImage.innerWidth();

      getOffsetTopList();
      onScrollWindow();
    }

    const onDocumentReady = () => {
      windowHeight = $window.height();
      windowWidth = $window.width();
      landingImageScrollImageWidth = $landingImageScrollImage.innerWidth();
      
      onResizeWindow();

      $window.scroll(onScrollWindow);
      $window.resize(onResizeWindow);
    }

    $document.ready(onDocumentReady);
  }

})();
