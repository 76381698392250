import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $landing = $('.page-template-landing');
  const hasLanding = $landing && $landing.length;

  if (hasWindow && hasDocument && hasLanding) {
    const $landingItem = $landing.find('.landing-item');
    let windowHeight = 0;

    const landingScrollAnimation = () => {
      const scrollTop = $window.scrollTop();
      let fixItemHeight = 0;

      $landingItem.each(index => {
        const $currentLandingItem = $landingItem.eq(index);

        let currentNewsItemVisiblePosition = $currentLandingItem.innerHeight() * fixItemHeight;
        currentNewsItemVisiblePosition = $currentLandingItem.offset().top + currentNewsItemVisiblePosition + 100;

        if (currentNewsItemVisiblePosition < scrollTop + windowHeight) {
          $currentLandingItem.addClass('visible');
        } else {
          $currentLandingItem.removeClass('visible');
        }
      });
    }

    const onResizeWindow = () => {
      windowHeight = $window.height();

      onWindowScroll();
    }

    const onWindowScroll = () => {
      landingScrollAnimation();
    }

    const onDocumentReady = () => {
      onResizeWindow();

      $window.resize(onResizeWindow);
      $window.scroll(onWindowScroll);
    }

    $document.ready(onDocumentReady);
  }

})();
