import $ from 'jquery';

;(() => {
  const $window = $(window);
  const hasWindow = $window && $window.length > 0;
  const $document = $(document);
  const hasDocument = $document && $document.length > 0;
  const $footer = $('#footer');
  const hasFooter = $footer && $footer.length > 0;
  const $logo = $('#logo');
  const hasLogo = $logo && $logo.length > 0;

  if (hasWindow && hasDocument && hasFooter && hasLogo) {
    let windowHeight = 0;
    let windowWidth = 0;
    let logoHeight = 0;
    let footerPaddingTop = 0;
    let footerOffsetTop = 0;
    let logoBottomPosition = 0;
    let inFooterPosition = 0;
    let fixedPosition = 0;

    const onResizeWindow = () => {
      windowHeight = $window.height();
      windowWidth = $window.width();
      logoHeight = $logo.innerHeight();
      footerPaddingTop = parseInt($footer.css('padding-top').replace('px', ''), 10);
      footerOffsetTop = $footer.offset().top;
      logoBottomPosition = windowWidth < 768 ? 25 : 50;
      inFooterPosition = footerOffsetTop - windowHeight + logoBottomPosition + logoHeight;
      fixedPosition = footerOffsetTop - windowHeight + footerPaddingTop + logoBottomPosition + logoHeight;
    };

    const onScrollWindow = () => {
      const scrollPosition = $window.scrollTop();
      onResizeWindow();
  
      if (scrollPosition >= inFooterPosition) {
        $logo.addClass('in-footer');
      } else {
        $logo.removeClass('in-footer');
      }
  
      if (scrollPosition >= fixedPosition) {
        $logo.removeClass('fixed');
      } else {
        $logo.addClass('fixed');
      }
    }

    const onDocumentReady = () => {
      setTimeout(() => {
        onScrollWindow();
        onResizeWindow();
      }, 50);
    }

    $document.ready(onDocumentReady);
    $window.scroll(onScrollWindow);
    $window.resize(onResizeWindow);
  }

})();
