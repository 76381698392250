import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $profilePage = $('.page-template-profile');
  const hasProfilePage = $profilePage && $profilePage.length;

  if (hasWindow && hasDocument && hasProfilePage) {
    const $profileItem = $profilePage.find('.profile-item');
    let windowHeight = 0;

    const profileStartAnimation = () => {
      let profileItemIndex = 0;
      const profileStartAnimationInterval = setInterval(() => {
        const $currentProfileItem = $profileItem.eq(profileItemIndex);
        const currentProfileItemVisiblePosition = $currentProfileItem.offset().top + ($currentProfileItem.innerHeight() / 2);

        if (currentProfileItemVisiblePosition < windowHeight) {
          $currentProfileItem.addClass('visible');
        } else {
          $profilePage.addClass('show-menu');
          clearInterval(profileStartAnimationInterval);
        }

        profileItemIndex = profileItemIndex + 1;
      }, 250)
    }

    const worksListViewScrollAnimation = () => {
      const scrollTop = $window.scrollTop();

      $profileItem.each(index => {
        const $currentProfileItem = $profileItem.eq(index);

        let fixPosition = 0;

        if ($currentProfileItem.hasClass('profile-image-carousel')) {
          fixPosition = 80;
        } else {
          fixPosition = $currentProfileItem.innerHeight() / 3;
        }

        const currentProfileItemPosition = $currentProfileItem.offset().top + fixPosition;

        if (currentProfileItemPosition < scrollTop + windowHeight) {
          $currentProfileItem.addClass('visible');
        } else {
          $currentProfileItem.removeClass('visible');
        }
      });
    }

    const onResizeWindow = () => {
      windowHeight = $window.height();

      onWindowScroll();
    }

    const onWindowScroll = () => {
      worksListViewScrollAnimation();
    }

    const onDocumentReady = () => {
      onResizeWindow();
      setTimeout(profileStartAnimation, 400);

      const $profileGoTo = $('.profile-go-to');
      const hasProfileGoTo = $profileGoTo && $profileGoTo.length;

      if (hasProfileGoTo) {
        const profileGoToValue = $profileGoTo.attr('value');

        if (profileGoToValue && profileGoToValue.length) {
          const $elementGoTo = $(`#${profileGoToValue}`);
          const hasElementGoTo = $elementGoTo && $elementGoTo.length;

          if (hasElementGoTo) {
            const fixScrollPosition = windowHeight >= 1024 ? 300 : 150;

            setTimeout(() => {
              $('html, body').animate({
                scrollTop: $elementGoTo.offset().top - fixScrollPosition,
              }, 1200);
            }, 300);
          }
        }
      }

      $window.resize(onResizeWindow);
      $window.scroll(onWindowScroll);
    }

    $document.ready(onDocumentReady);
  }

})();
