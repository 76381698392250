import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $imageCarouselComponent = $('.image-carousel-component');
  const hasImageCarouselComponent = $imageCarouselComponent && $imageCarouselComponent.length;

  if (hasWindow && hasDocument && hasImageCarouselComponent) {
    const animationTime = 2 * 1000;

    let carouselIntervalList = [];

    const setupCarousel = ($carousel, carouselIndex) => {
      if (!$carousel || !$carousel.length) {
        return;
      }

      const $currentCarouselBox = $carousel.find('.carousel-box');
      const currentCarouselBoxAmount = $currentCarouselBox.length;
      const $paginationButton = $carousel.find('.carousel-pagination .carousel-pagination-button');
      const hasPaginationButton = $paginationButton && $paginationButton.length;

      
      $carousel.attr('carousel-index', carouselIndex);
      $carousel.attr('box-amount', currentCarouselBoxAmount);
      $carousel.attr('current-box', 0);
      carouselIntervalList.push(null);

      if (hasPaginationButton) {
        $paginationButton.click(onClickPaginationButton);
      }

      updateCarousel($carousel, 0);
    }

    const updateCarousel = ($carousel, position) => {
      if (!$carousel || !$carousel.length) {
        return;
      }

      const boxAmount = parseInt($carousel.attr('box-amount'), 10);
      const $currentCarouselImageBox = $carousel.find('.image-carousel-wrapper .carousel-box');
      const hasCurrentCarouselImageBox = $currentCarouselImageBox
        && $currentCarouselImageBox.length;
      const $currentCarouselDescriptionText = $carousel.find('.description-text');
      const hasCurrentCarouselDescriptionText = $currentCarouselDescriptionText
        && $currentCarouselDescriptionText.length;
      const $currentCarouselPaginationButton = $carousel.find(
        '.carousel-pagination .carousel-pagination-button',
      );
      const hasCurrentCarouselPaginationButton = $currentCarouselPaginationButton
        && $currentCarouselPaginationButton.length;

      if (position <= 0) {
        position = 0;
      }

      if (position >= boxAmount) {
        position = 0;
      }

      $carousel.attr('current-box', position);

      if (hasCurrentCarouselImageBox) {
        $currentCarouselImageBox.removeClass('visible');
        $currentCarouselImageBox.eq(position).addClass('visible');
      }

      if (hasCurrentCarouselDescriptionText) {
        $currentCarouselDescriptionText.removeClass('active');
        $currentCarouselDescriptionText.eq(position).addClass('active');
      }

      if (hasCurrentCarouselPaginationButton) {
        $currentCarouselPaginationButton.removeClass('current');
        $currentCarouselPaginationButton.eq(position).addClass('current');
      }
    }

    const resetCarouselInterval = $carousel => {
      const carouselIndex = parseInt($carousel.attr('carousel-index'), 10);

      if (Number.isInteger(carouselIndex)) {
        const currentInterval = carouselIntervalList[carouselIndex];
        const carouselTransitionTimeAttribute = parseInt($carousel.attr('transition-time'), 10);
        let carouselTime = animationTime;

        if (Number.isInteger(carouselTransitionTimeAttribute)) {
          carouselTime = carouselTransitionTimeAttribute;
        }

        if (currentInterval) {
          clearInterval(currentInterval);
        }

        carouselIntervalList[carouselIndex] = setInterval(
          () => { carouselIntervalFunction($carousel); },
          carouselTime,
        );
      }
    }

    const carouselIntervalFunction = $carousel => {
      let currentBox = parseInt($carousel.attr('current-box'), 10);

      if (Number.isInteger(currentBox)) {
        currentBox = currentBox + 1;

        updateCarousel($carousel, currentBox);
      }
    }

    const onClickPaginationButton = event => {
      const $currentTarget = $(event.currentTarget);
      const $carousel = $currentTarget.closest('.image-carousel-component');
      const carouselIndex = parseInt($currentTarget.attr('carousel-index'), 10);

      if (Number.isInteger(carouselIndex)) {
        updateCarousel($carousel, carouselIndex);
        resetCarouselInterval($carousel);
      }
    }

    const onWindowResize = () => {
      $imageCarouselComponent.each(index => {
        const $currentImageCarouselComponent = $imageCarouselComponent.eq(index);

        if ($currentImageCarouselComponent && $currentImageCarouselComponent.length) {
          resetCarouselInterval($currentImageCarouselComponent);
        }
      });
    }

    const onDocumentReady = () => {
      onWindowResize();

      $window.resize(onWindowResize);

      $imageCarouselComponent.each(index => {
        const $currentCarousel = $imageCarouselComponent.eq(index);

        setupCarousel($currentCarousel, index);
        resetCarouselInterval($currentCarousel);
      });
    }

    $document.ready(onDocumentReady)
  }

})();
