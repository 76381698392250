import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $landing = $('.page-template-landing');
  const hasLanding = $landing && $landing.length;
  const $landingCoverSlideshow = $landing.find('.landing-cover-slideshow');
  const hasLandingCoverSlideshow = $landingCoverSlideshow && $landingCoverSlideshow.length;
  const $landingContentWrapper = $landing.find('.landing-content-wrapper');
  const hasLandingContentWrapper = $landingContentWrapper && $landingContentWrapper.length;

  if (
    hasWindow &&
    hasDocument &&
    hasLanding &&
    hasLandingCoverSlideshow &&
    hasLandingContentWrapper
  ) {
    const $landingCoverItem = $landingCoverSlideshow.find('.landing-cover-item');
    let coverAmount = 0;
    let windowHeight = 0;
    let windowScrollTop = 0;
    let currentCoverIndex = 0;
    let slideshowTransitionInterval = 3000;
    let scrollToLandingContentWrapperTimeout = null;

    const scrollToLandingContentWrapperFunction = () => {
      $('html, body').animate({ scrollTop: windowHeight }, 600);
    }

    const coverAnimationIntervalFunction = () => {
      if (currentCoverIndex >= coverAmount) {
        currentCoverIndex = 0;

        if (windowScrollTop === 0 && !$landing.hasClass('auto-scrolled')) {
          console.log('caiu');
          $landing.addClass('auto-scrolled');
          scrollToLandingContentWrapperFunction();
        }
      }

      const $currentCoverItem = $landingCoverItem.eq(currentCoverIndex);

      if (!$currentCoverItem.hasClass('cover-item-text') && !$currentCoverItem.hasClass('skip-cover')) {
        $currentCoverItem.addClass('active skip-cover');

      } else {
        $landingCoverItem.removeClass('active skip-cover');
        $currentCoverItem.addClass('active');
        currentCoverIndex = currentCoverIndex + 1;
      }
    }

    const onWindowScroll = () => {
      if (scrollToLandingContentWrapperTimeout) {
        clearTimeout(scrollToLandingContentWrapperTimeout);
      }
    }

    const onResizeWindow = () => {
      windowScrollTop = $window.scrollTop();
      windowHeight = $window.height();
    }

    const onDocumentReady = () => {
      const transitionTime = parseInt($landingCoverSlideshow.attr('transition-time'), 10);
      const transitionTimeIsInteger = Number.isInteger(transitionTime);
      const timeAutoscroll = parseInt($landingCoverSlideshow.attr('time-autoscroll'), 10);
      const timeAutoscrollIsInteger = Number.isInteger(timeAutoscroll);

      coverAmount = $landingCoverItem.length;

      if (transitionTimeIsInteger && transitionTime > 0) {
        slideshowTransitionInterval = transitionTime;
      } else {
        slideshowTransitionInterval = 3000;
      }

      windowScrollTop = $window.scrollTop();

      if (coverAmount > 1 && slideshowTransitionInterval) {
        currentCoverIndex = 1;
        setInterval(coverAnimationIntervalFunction, slideshowTransitionInterval / 2);
      }

      onResizeWindow();
    }

    $window.resize(onResizeWindow);
    $window.scroll(onWindowScroll);
    $document.ready(onDocumentReady);
  }

})();
