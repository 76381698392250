import './js/pages/landing.js';
import './js/pages/profile.js';
import './js/pages/single-work.js';
import './js/pages/archive-work.js';
import './js/pages/archive-news.js';

import './js/components/header.js';
import './js/components/logo.js';
import './js/components/footer-prompt.js';
import './js/components/scroll-carousel-component.js';
import './js/components/image-carousel-component.js';
import './js/components/landing-cover-slideshow.js';
import './js/components/landing-image-scroll.js';
import './js/components/profile-accordion.js';

import './scss/style.scss';
