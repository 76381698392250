import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $singleWork = $('.single-work');
  const hasSingleWork = $singleWork && $singleWork.length;

  if (hasWindow && hasDocument && hasSingleWork) {
    const $singleWorkItem = $singleWork.find('.single-work-item');
    let windowHeight = 0;

    const singleWorkStartAnimation = () => {
      let singleWorkItemIndex = 0;
      const singleWorkStartAnimationInterval = setInterval(() => {
        const $currentSingleWorkItem = $singleWorkItem.eq(singleWorkItemIndex);
        const hasCurrentSingleWorkItem = $currentSingleWorkItem && $currentSingleWorkItem.length;

        if (hasCurrentSingleWorkItem) {
          const currentSingleWorkItemVisiblePosition = $currentSingleWorkItem.offset().top + 50;

          if (currentSingleWorkItemVisiblePosition < windowHeight) {
            $currentSingleWorkItem.addClass('visible');
          } else {
            $singleWork.addClass('show-menu');
            clearInterval(singleWorkStartAnimationInterval);
          }
        } else {
          $singleWork.addClass('show-menu');
          clearInterval(singleWorkStartAnimationInterval);
        }

        singleWorkItemIndex = singleWorkItemIndex + 1;
      }, 250)
    }

    const singleWorkScrollAnimation = () => {
      const scrollTop = $window.scrollTop();

      $singleWorkItem.each(index => {
        const $currentSingleWorkItem = $singleWorkItem.eq(index);
        const currentSingleWorkItemPosition = $currentSingleWorkItem.offset().top + 50;

        if (currentSingleWorkItemPosition < scrollTop + windowHeight) {
          $currentSingleWorkItem.addClass('visible');
        } else {
          $currentSingleWorkItem.removeClass('visible');
        }
      });
    }

    const onResizeWindow = () => {
      windowHeight = $window.height();

      onWindowScroll();
    }

    const onWindowScroll = () => {
      singleWorkScrollAnimation();
    }

    const onDocumentReady = () => {
      onResizeWindow();
      setTimeout(singleWorkStartAnimation, 400);

      $window.resize(onResizeWindow);
      $window.scroll(onWindowScroll);
    }

    $document.ready(onDocumentReady);
  }

})();
