import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $scrollCarouselComponent = $('.scroll-carousel-component');
  const hasScrollCarouselComponent = $scrollCarouselComponent && $scrollCarouselComponent.length;

  if (hasWindow && hasDocument && hasScrollCarouselComponent) {
    const animationTime = 2 * 1000;
    let windowHeight = 0;
    let windowWidth = 0;
    let offsetTopList = [];
    let carouselBoxWidthList = [];
    let carouselIntervalList = [];

    const updateOffsetTopList = () => {
      offsetTopList = [];

      $scrollCarouselComponent.each((index) => {
        offsetTopList.push($scrollCarouselComponent.eq(index).offset().top);
      });
    }

    const uptadeCarouselBoxWidthList = () => {
      carouselBoxWidthList = [];

      $scrollCarouselComponent.each((index, carousel) => {
        const $carousel = $(carousel);

        carouselBoxWidthList.push($carousel.find('.scroll-carousel-wrapper').innerWidth());
      });
    }

    const updateCarousel = ($carousel, position) => {
      const $scrollCarouselBoxWrapper = $carousel.find('.scroll-carousel-box-wrapper');
      const $carouselNav = $carousel.find('.scroll-carousel-nav');
      const $carouselNavItem = $carouselNav.find('.carousel-nav-item');
      const $currentCarouselNavItemButtons = $carouselNav.find(
        '.carousel-nav-item .item-nav-button',
      );
      const $currentCarouselNavItem = $carouselNavItem.eq(position);
      const $currentCarouselNavButton = $currentCarouselNavItem.find('.item-nav-button');
      const $scrollCarouselItemTextCont = $carousel.find('.scroll-carousel-item-text-cont');
      const navTextColor = $currentCarouselNavButton.attr('nav-text-color');

      $carouselNav.removeClass('text-color-white text-color-black');
      $scrollCarouselBoxWrapper.removeClass('visible');
      $scrollCarouselItemTextCont.removeClass('visible');
      $currentCarouselNavItemButtons.removeClass('current');

      $carouselNav.addClass(`text-color-${navTextColor}`);
      $scrollCarouselBoxWrapper.eq(position).addClass('visible');
      $scrollCarouselItemTextCont.eq(position).addClass('visible');
      $currentCarouselNavButton.addClass('current');
    }

    const restartUptadeCarouselInterval = $carousel => {
      const carouselIndex = parseInt($carousel.attr('carousel-index'), 10);

      if (Number.isInteger(carouselIndex)) {
        const currentInterval = carouselIntervalList[carouselIndex];
        const carouselTransitionTimeAttribute = parseInt($carousel.attr('transition-time'), 10);
        let carouselTime = animationTime;

        if (Number.isInteger(carouselTransitionTimeAttribute)) {
          carouselTime = carouselTransitionTimeAttribute;
        }

        if (currentInterval) {
          clearInterval(currentInterval);
        }

        carouselIntervalList[carouselIndex] = setInterval(
          () => { uptadeCarouselIntervalFunction($carousel) },
          carouselTime,
        );
      }
    }

    const updateLandingImageScroll = ($carousel, scrollPosition, offsetTop, carouselBoxWidth) => {
      if (!($carousel && $carousel.length) || $carousel.hasClass('carousel-finished')) {
        return;
      }

      const $carouselWrapper = $carousel.find('.scroll-carousel-wrapper');
      const growthPosition = offsetTop - (windowHeight / 2);
      const carouselFinishedPosition = offsetTop;

      if (scrollPosition < growthPosition) {
        if ($carousel.hasClass('carousel-enabled')) {
          $carousel.removeClass('carousel-enabled');
        }

        if ($carousel.hasClass('growth')) {
          $carousel.removeClass('growth');
        }

        $carouselWrapper.css('width', '');
      }

      if (scrollPosition >= growthPosition && scrollPosition < carouselFinishedPosition) {
        if ($carousel.hasClass('carousel-enabled')) {
          $carousel.removeClass('carousel-enabled');
        }

        if (!$carousel.hasClass('growth')) {
          $carousel.addClass('growth');
        }

        let percente = scrollPosition - growthPosition;
        percente = percente / (carouselFinishedPosition - growthPosition);
        const widthToGrow = windowWidth - carouselBoxWidth;
        const carouselWrapperWidth = carouselBoxWidth + (widthToGrow * percente);

        $carouselWrapper.css('width', `${carouselWrapperWidth}px`);
      }

      if (scrollPosition > carouselFinishedPosition) {
        const boxAmount = parseInt($carousel.attr('box-amount'), 10);

        if (!$carousel.hasClass('carousel-finished')) {
          $carousel.addClass('carousel-finished');
        }

        if ($carousel.hasClass('growth')) {
          $carousel.removeClass('growth');
        }

        if (!$carousel.hasClass('carousel-enabled')) {
          $carousel.addClass('carousel-enabled');
        }

        if (boxAmount >= 2) {
          restartUptadeCarouselInterval($carousel);
        }

        $carouselWrapper.css('width', '');
      }
    }

    const uptadeCarouselIntervalFunction = $carousel => {
      if ($carousel.hasClass('carousel-enabled')) {
        const boxAmount = parseInt($carousel.attr('box-amount'), 10);
        let currentBox = parseInt($carousel.attr('current-box'), 10);
        currentBox = currentBox + 1

        if (currentBox > boxAmount - 1) {
          currentBox = 0;
        }

        $carousel.attr('current-box', currentBox);

        updateCarousel($carousel, currentBox);
      }
    }

    const onScrollWindow = () => {
      const scrollPosition = $window.scrollTop();

      $scrollCarouselComponent.each((index, carousel) => {
        const $carousel = $(carousel);

        updateLandingImageScroll(
          $carousel,
          scrollPosition,
          offsetTopList[index],
          carouselBoxWidthList[index],
        );
      });
    }

    const onResizeWindow = () => {
      windowHeight = $window.height();
      windowWidth = $document.width();
      $scrollCarouselComponent.removeClass('carousel-enabled carousel-finished growth');

      const $scrollCarouselComponentWrapper = $scrollCarouselComponent.find(
        '.scroll-carousel-wrapper',
      );

      if ($scrollCarouselComponentWrapper && $scrollCarouselComponentWrapper.length) {
        $scrollCarouselComponentWrapper.css('width', '');
      }

      uptadeCarouselBoxWidthList();
      updateOffsetTopList();
      onScrollWindow();
    }

    const onDocumentReady = () => {
      onResizeWindow();

      $window.scroll(onScrollWindow);
      $window.resize(onResizeWindow);

      $scrollCarouselComponent.each((index, carousel) => {
        const $carousel = $(carousel);
        const $scrollCarouselBoxList = $carousel.find('.scroll-carousel-box-list');
        const $scrollCarouselBoxWrapper = $carousel.find('.scroll-carousel-box-wrapper');
        const $carouselNav = $carousel.find('.scroll-carousel-nav');
        const $carouselNavItems = $carouselNav.find('.carousel-nav-item');
        const $carouselNavButtons = $carouselNavItems.find('.item-nav-button');
        const $scrollCarouselItemTextCont = $carousel.find('.scroll-carousel-item-text-cont');
        const $firstCarouselNavButton = $carouselNavItems.eq(0).find('.item-nav-button');

        $carousel.attr('carousel-index', index);
        $carousel.attr('current-box', 0);
        $carousel.attr('box-amount', $scrollCarouselBoxList.find(
          '.scroll-carousel-box-wrapper',
        ).length);

        carouselIntervalList.push(null);

        if (index === 0) {
          const firstNavTextColor = $carouselNavButtons.eq(index).attr('nav-text-color');

          $carouselNav.removeClass('text-color-white text-color-black');
          $carouselNav.addClass(`text-color-${firstNavTextColor}`);
        }

        if ($scrollCarouselItemTextCont && $scrollCarouselItemTextCont.length) {
          $scrollCarouselItemTextCont.eq(0).addClass('visible');
        }

        if ($scrollCarouselBoxWrapper && $scrollCarouselBoxWrapper.length) {
          $scrollCarouselBoxWrapper.eq(0).addClass('visible');
        }

        if ($firstCarouselNavButton && $firstCarouselNavButton.length) {
          $firstCarouselNavButton.addClass('current');
        }

        $carouselNavButtons.click(event => {
          const $currentTarget = $(event.currentTarget);
          const boxIndex = parseInt($currentTarget.attr('nav-index'), 10);
          const navTextColor = $currentTarget.attr('nav-text-color');

          restartUptadeCarouselInterval($carousel);

          $carouselNav.removeClass('text-color-white text-color-black');
          $carouselNav.addClass(`text-color-${navTextColor}`);
          $carousel.attr('current-box', boxIndex);

          updateCarousel($carousel, boxIndex);

          event.preventDefault();
        });
      });

      onResizeWindow();
    }

    $document.ready(onDocumentReady);
  }

})();
