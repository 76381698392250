import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $newsArchive = $('.post-type-archive-news');
  const hasNewsArchive = $newsArchive && $newsArchive.length;

  if (hasWindow && hasDocument && hasNewsArchive) {
    const $newsGallery = $newsArchive.find('.news-gallery');
    const hasNewsGallery = $newsGallery && $newsGallery.length;
    const $newsItem = $newsArchive.find('.news-item');
    let windowWidth = 0;
    let windowHeight = 0;
    let newsArchiveStartAnimation = null;

    const newsArchiveStartAnimationFunction = () => {
      $newsItem.removeClass('visible');
      $newsArchive.removeClass('show-menu');

      let newsItemIndex = 0;
      newsArchiveStartAnimation = setInterval(() => {
        const $currentNewsItem = $newsItem.eq(newsItemIndex);
        let fixItemHeight = 0.4;
        let currentNewsItemVisiblePosition = $currentNewsItem.innerHeight() * fixItemHeight;
        currentNewsItemVisiblePosition = $currentNewsItem.offset().top + currentNewsItemVisiblePosition;

        if (newsItemIndex <= 2 || currentNewsItemVisiblePosition < windowHeight) {
          $currentNewsItem.addClass('visible');
        } else {
          $newsArchive.addClass('show-menu');
          clearInterval(newsArchiveStartAnimation);
        }

        newsItemIndex = newsItemIndex + 1;
      }, 1000);
    }

    const newsArchiveScrollAnimation = () => {
      const scrollTop = $window.scrollTop();

      $newsItem.each(index => {
        const $currentNewsItem = $newsItem.eq(index);
        let currentNewsItemVisiblePosition = 200;

        if (windowHeight >= 1194 && windowHeight < 1366) {
          currentNewsItemVisiblePosition = 250;
        } else if (windowHeight >= 1366) {
          currentNewsItemVisiblePosition = 400;
        }

        currentNewsItemVisiblePosition = $currentNewsItem.offset().top + currentNewsItemVisiblePosition;

        if (index <= 1 ||  currentNewsItemVisiblePosition < (scrollTop + windowHeight)) {
          $currentNewsItem.addClass('visible');
        } else {
          $currentNewsItem.removeClass('visible');
        }
      });
    }

    const onResizeWindow = () => {
      windowHeight = $window.height();
      windowWidth = $window.width();
    }

    const onWindowScroll = () => {
      if (!$newsArchive.hasClass('show-menu')) {
        $newsArchive.addClass('show-menu');
      }

      onResizeWindow();
      newsArchiveScrollAnimation();
    }

    const onDocumentReady = () => {
      onResizeWindow();

      const scrollTop = $window.scrollTop();

      if (scrollTop === 0) {
        const articleGoToSlug = $newsGallery.attr('article');
        const $articleGoTo = $(`#${articleGoToSlug}`);

        if ($articleGoTo && $articleGoTo.length) {
          setTimeout(() => {
            $('html, body').animate({ scrollTop: $articleGoTo.offset().top }, 800);
          }, 300);

          onWindowScroll();
        } else {
          newsArchiveStartAnimationFunction();
        }
      } else {
        onWindowScroll();
      }

      $window.resize(onResizeWindow);
      $window.scroll(onWindowScroll);
    }

    $document.ready(onDocumentReady);
  }

})();
